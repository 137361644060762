<template>
  <div class="about">
    <img src="@/assets/Hero_Image_AboutUs.png" alt="Hero Image" class="hero-image">
    <h1>About Us</h1>
    <p>Welcome to Fylter!</p>
    <p>Revolutionizing job search and recruitment, Fylter introduces a unique, swipe-based approach, enabling you to effortlessly apply for or reject jobs directly from your mobile device. We're committed to streamlining your experience by focusing on skill-based matching—connecting job seekers and employers cost-effectively, without reliance on outdated keyword matching or unnecessary fees. Join us in transforming how you find or fill jobs, making the process more efficient and fair for everyone.</p>

    <h2>Our Founding Story</h2>
    <p>Fylter was envisioned by Reid Rhodes, whose extensive background in tech and finance uniquely positioned him to identify inefficiencies in the recruitment process. Witnessing the excessive costs and operational inefficiencies in the job market, Reid was motivated to create a solution that would democratize the recruitment process. Fylter was born out of this vision to lower costs for employers and eliminate wasted time for job seekers. Our platform leverages cutting-edge AI technology to match job seekers with opportunities based on skills and potential, ensuring a fair and efficient marketplace for all.</p>
  
    <h2>Our Mission</h2>
    <p>Our mission at Fylter is to redefine the job search and recruitment landscape. We aim to drastically cut down on the wasted time job seekers often face and significantly reduce the financial and operational risks for employers. By focusing on a skill-based matching system, we ensure a fairer and more effective recruitment process. This approach helps connect the right candidates with the right opportunities, bypassing outdated methods that rely on job title matching and preferred treatment for increased advertisement spend. We are committed to making recruitment accessible, efficient, and equitable for all.</p>

    <h2>Why Fylter?</h2>
    <ul>
      <li><strong>Save Time:</strong> Automated skill matching and swipe-based applications streamline the job search and hiring process, significantly reducing the time spent by both job seekers and employers.</li>
      <li><strong>Lower Costs:</strong> By eliminating sponsored listings and keyword bidding, our platform reduces expenses for employers, making job posting more economical and transparent.</li>
      <li><strong>Improved User Experience:</strong> Our intuitive, mobile-friendly interface with swipe functionality makes job searching and applying more engaging and straightforward, mimicking the ease of popular social apps.</li>
      <li><strong>Diverse Opportunities:</strong> Skill-based matching allows job seekers to discover roles beyond their current industry or job title, broadening their career prospects and potential for growth.</li>
      <li><strong>Reduced Bias:</strong> By focusing on skills rather than job titles or personal factors, we help minimize unconscious bias, promoting a fairer, more inclusive hiring process.</li>
    </ul>

    <h2>Technology and Innovation at Fylter</h2>
    <p>At Fylter, innovation is at the core of everything we do. We harness the power of advanced AI technology and have developed a skill-based matching system that not only meets but surpasses the traditional job searching and recruitment methods. Our technology is designed to ensure precision in matching job seekers with employers, creating an efficient, bias-free, and enjoyable job search experience.</p>
    <p>By continuously refining our algorithms and user interfaces, we remain at the forefront of the recruitment technology sector, ensuring our users receive the most effective and user-friendly job search tools available.</p>
  
    <h2>Our Future Plans</h2>
    <p>As we continue to grow, we are excited about the future of Fylter. We are constantly working on new features and enhancements to improve the user experience for both job seekers and employers. Stay tuned for exciting updates as we expand our services and reach.</p>
    
    <h2>Get in Touch</h2>
    <p>We're eager to hear from you and assist you in any way we can. Whether you have questions, need support, or want to provide feedback, our dedicated team is ready to help. For a quick response, please visit our <a href="/contact">Contact Page</a> and fill out our contact form. Let’s transform the job market together!</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  mounted() {
    document.body.style.zoom = "75%";
  }
}
</script>

<style scoped>
.about {
  text-align: center;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f0f4f8;
}

.hero-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.about h2 {
  margin-top: 30px;
}

.about p, .about ul {
  text-align: left;
  line-height: 1.6;
}

.about ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
