<template>
  <div class="job-portal">
    <div class="form-container">
      <h1>Add New Job</h1>
      <p>For tips on optimizing your job postings, <router-link to="/optimize-postings" target="_blank">click here</router-link>.</p>
      <form @submit.prevent="addJob">
        <div class="form-group">
          <label for="job-title">Job Title 
            <span class="tooltip-icon" @mouseover="showTooltip('job-title')" @mouseout="hideTooltip('job-title')">?</span>
          </label>
          <input type="text" id="job-title" v-model="newJob.title" placeholder="Enter job title">
          <div v-if="tooltips['job-title']" class="tooltip">Specificity is key. Avoid long titles as they do not display well on mobile apps and are not appealing to job seekers.</div>
        </div>
        <div class="form-group">
          <label for="company-name">Company Name 
            <span class="tooltip-icon" @mouseover="showTooltip('company-name')" @mouseout="hideTooltip('company-name')">?</span>
          </label>
          <input type="text" id="company-name" v-model="newJob.company" placeholder="Enter company name">
          <div v-if="tooltips['company-name']" class="tooltip">Ensure accuracy, but note that the company name does not impact the matching process.</div>
        </div>
        <div class="form-group">
          <label for="location">Location 
            <span class="tooltip-icon" @mouseover="showTooltip('location')" @mouseout="hideTooltip('location')">?</span>
          </label>
          <input type="text" id="location" v-model="search" @input="onSearch" placeholder="City, State" v-if="!newJob.globallyRemote">
          <div v-if="tooltips['location']" class="tooltip">Enter the city and state if the job is not globally remote. Use the autocomplete suggestions to ensure a valid location.</div>
          <ul v-if="isOpen && filteredCities.length && !newJob.globallyRemote" class="autocomplete-suggestions">
            <li v-for="city in filteredCities" :key="city.city" @click="setLocation(city)">
              {{ city.city }}, {{ city.state }}
            </li>
          </ul>
        </div>
        <div class="form-group">
          <label for="job-type">Job Type 
            <span class="tooltip-icon" @mouseover="showTooltip('job-type')" @mouseout="hideTooltip('job-type')">?</span>
          </label>
          <select id="job-type" v-model="newJob.type">
            <option disabled value="">Select Job Type</option>
            <option value="Full-Time">Full-Time</option>
            <option value="Part-Time">Part-Time</option>
            <option value="Contract">Contract</option>
          </select>
          <div v-if="tooltips['job-type']" class="tooltip">Select the appropriate job type. Job seekers will only see jobs that match the type they are searching for.</div>
        </div>
        <div class="form-group">
          <input type="checkbox" v-model="newJob.globallyRemote" id="globally-remote" @change="handleRemoteChange">
          <label for="globally-remote">Globally Remote 
            <span class="tooltip-icon" @mouseover="showTooltip('globally-remote')" @mouseout="hideTooltip('globally-remote')">?</span>
          </label>
          <div v-if="tooltips['globally-remote']" class="tooltip">Check this if the job can be performed from anywhere in the world.</div>
        </div>
        <div class="form-group" v-if="!newJob.globallyRemote">
          <input type="checkbox" v-model="stateSpecificRemote" id="state-specific-remote" @change="handleRemoteChange">
          <label for="state-specific-remote">Remote within Specific States 
            <span class="tooltip-icon" @mouseover="showTooltip('state-specific-remote')" @mouseout="hideTooltip('state-specific-remote')">?</span>
          </label>
          <div v-if="tooltips['state-specific-remote']" class="tooltip">Select this if the job can be done remotely within specific states. Hybrid roles requiring commuting should not be marked as remote.</div>
        </div>
        <div class="form-group" v-if="stateSpecificRemote && !newJob.globallyRemote">
          <label for="remote-states">Select States</label>
          <div class="state-checkboxes">
            <div v-for="state in states" :key="state" class="state-checkbox">
              <input type="checkbox" :id="'state-' + state" :value="state" v-model="newJob.remoteStates">
              <label :for="'state-' + state">{{ state }}</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description">Job Description 
            <span class="tooltip-icon" @mouseover="showTooltip('description')" @mouseout="hideTooltip('description')">?</span>
          </label>
          <textarea ref="description" id="description" v-model="newJob.description" placeholder="Describe the role"></textarea>
          <div v-if="tooltips['description']" class="tooltip">Aim for a concise description, with a maximum of 150 words. Generally, 100-125 words are preferred by mobile users.</div>
          <div class="markdown-buttons">
            <button type="button" @click="addMarkdown('**', '**', 'bold')">Bold</button>
            <button type="button" @click="addMarkdown('*', '*', 'italic')">Italic</button>
            <button type="button" @click="addMarkdown('\\n\n-', '', 'list')">List Item</button>
            <button type="button" @click="addMarkdown('### ', '', 'heading')">Heading</button>
            <button type="button" @click="addMarkdown('\\n\n', '', 'newline')">New Line</button>
          </div>
          <div class="tooltip-wrapper">
            <button type="button" @click="generateJobDescription" class="generate-ai-button" :disabled="isGenerating">
              Generate with AI
              <span v-if="isGenerating" class="loading-spinner"></span>
            </button>
            <span class="tooltip-icon" @mouseover="showTooltip('generate-ai')" @mouseout="hideTooltip('generate-ai')">?</span>
            <div v-if="tooltips['generate-ai']" class="tooltip">Given your input for Company Name and Job Title, we generate a job description that is proven to be successful for your role.</div>
          </div>
        </div>
        <div class="form-group">
          <h3 v-if="newJob.requiredSkills.length > 0">Required Skills:</h3>
          <div v-for="(skill, index) in newJob.requiredSkills" :key="'required-' + index" class="skill-group">
            <input type="text" v-model="skill.skill" placeholder="Required Skill Name">
            <select v-model="skill.desiredLevel">
              <option disabled value="">Select Skill Level</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
            <button @click.prevent="removeSkill(index, true)">Remove Skill</button>
          </div>
          <div class="tooltip-wrapper">
            <button @click.prevent="addRequiredSkill" class="add-skill-button">Add Required Skill</button>
            <span class="tooltip-icon" @mouseover="showTooltip('required-skills')" @mouseout="hideTooltip('required-skills')">?</span>
            <div v-if="tooltips['required-skills']" class="tooltip">List up to 2-3 essential skills. These should be skills absolutely necessary for the role.</div>
          </div>
        </div>
        <div class="form-group">
          <h3 v-if="newJob.desiredSkills.length > 0">Desired Skills:</h3>
          <div v-for="(skill, index) in newJob.desiredSkills" :key="'desired-' + index" class="skill-group">
            <input type="text" v-model="skill.skill" placeholder="Desired Skill Name">
            <select v-model="skill.desiredLevel">
              <option disabled value="">Select Skill Level</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
            <button @click.prevent="removeSkill(index, false)">Remove Skill</button>
          </div>
          <div class="tooltip-wrapper">
            <button @click.prevent="addDesiredSkill" class="add-skill-button">Add Desired Skill</button>
            <span class="tooltip-icon" @mouseover="showTooltip('desired-skills')" @mouseout="hideTooltip('desired-skills')">?</span>
            <div v-if="tooltips['desired-skills']" class="tooltip">List up to 2-3 additional skills that would be advantageous but not mandatory.</div>
          </div>
        </div>
        <div class="form-group">
          <h3 v-if="newJob.requiredCertificates.length > 0">Required Certificates:</h3>
          <div v-for="(certificate, index) in newJob.requiredCertificates" :key="'required-cert-' + index" class="skill-group">
            <input type="text" v-model="certificate.certificate" placeholder="Required Certificate Name">
            <button @click.prevent="removeCertificate(index)">Remove Certificate</button>
          </div>
          <div class="tooltip-wrapper">
            <button @click.prevent="addRequiredCertificate" class="add-skill-button">Add Required Certificate</button>
            <span class="tooltip-icon" @mouseover="showTooltip('required-certificates')" @mouseout="hideTooltip('required-certificates')">?</span>
            <div v-if="tooltips['required-certificates']" class="tooltip">Only input certificates that will disqualify job seekers if they do not have them. E.g., Certified EKG Technician (CET) for EKG Technicians.</div>
          </div>
        </div>
        <div class="form-group">
          <label for="min-salary">Minimum Salary 
            <span class="tooltip-icon" @mouseover="showTooltip('min-salary')" @mouseout="hideTooltip('min-salary')">?</span>
          </label>
          <input type="number" id="min-salary" v-model="newJob.salaryRange.min" placeholder="e.g., 50000">
          <div v-if="tooltips['min-salary']" class="tooltip">Provide a realistic minimum salary.</div>
        </div>
        <div class="form-group">
          <label for="max-salary">Maximum Salary 
            <span class="tooltip-icon" @mouseover="showTooltip('max-salary')" @mouseout="hideTooltip('max-salary')">?</span>
          </label>
          <input type="number" id="max-salary" v-model="newJob.salaryRange.max" placeholder="e.g., 100000">
          <div v-if="tooltips['max-salary']" class="tooltip">Provide a realistic maximum salary.</div>
        </div>
        <button type="submit" class="submit-button">Add Job</button>
      </form>
    </div>
    <div class="preview-container">
      <div class="iphone-mockup">
        <div class="iphone-screen">
          <div class="job-preview">
            <h2>{{ newJob.title }}</h2>
            <h3>{{ newJob.company }}</h3>
            <p>{{ newJob.location }} <span v-if="newJob.remote">(Remote)</span></p>
            <p>Salary: ${{ newJob.salaryRange.min }} - ${{ newJob.salaryRange.max }}</p>
            <div class="job-description" v-html="parsedDescription"></div>
            <h3 v-if="newJob.requiredSkills.length > 0">Required Skills</h3>
            <ul v-if="newJob.requiredSkills.length > 0">
              <li v-for="(skill, index) in newJob.requiredSkills" :key="'req-skill-' + index">
                {{ skill.skill }} ({{ skill.desiredLevel }})
              </li>
            </ul>
            <h3 v-if="newJob.desiredSkills.length > 0">Desired Skills</h3>
            <ul v-if="newJob.desiredSkills.length > 0">
              <li v-for="(skill, index) in newJob.desiredSkills" :key="'des-skill-' + index">
                {{ skill.skill }} ({{ skill.desiredLevel }})
              </li>
            </ul>
            <h3 v-if="newJob.requiredCertificates.length > 0">Required Certificates</h3>
            <ul v-if="newJob.requiredCertificates.length > 0">
              <li v-for="(certificate, index) in newJob.requiredCertificates" :key="'req-cert-' + index">
                {{ certificate.certificate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/firebase'; // Import Firebase configuration
import { getFirestore, collection, addDoc } from 'firebase/firestore'; // Import Firestore
import { getAuth } from 'firebase/auth'; // Import Firebase Authentication
import cities from '@/data/us_cities_with_coordinates.json'; // Assuming the data path
import { marked } from 'marked'; // Import marked correctly as per latest version

export default {
  data() {
    return {
      newJob: {
        title: '',
        company: '',
        companyId: '',
        coordinates: { latitude: null, longitude: null },
        datePosted: new Date(),
        description: '',
        desiredSkills: [],
        isActive: true,
        location: '',
        remote: false,
        globallyRemote: false,
        remoteStates: [],
        remoteStatesMap: {}, // Add this property to store selected states as a map
        requiredSkills: [],
        requiredCertificates: [], // Add this line
        salaryRange: { min: 0, max: 0 },
        type: ''
      },
      stateSpecificRemote: false, // Local state, not part of newJob
      search: '',
      isOpen: false,
      filteredCities: [],
      tooltips: {
        'job-title': false,
        'company-name': false,
        'location': false,
        'job-type': false,
        'globally-remote': false,
        'state-specific-remote': false,
        'description': false,
        'required-skills': false,
        'desired-skills': false,
        'min-salary': false,
        'max-salary': false,
        'required-certificates': false, // Add this line
        'generate-ai': false // Add this line
      },
      states: [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
      ], // Add a comprehensive list of states
      isGenerating: false // Add this property
    };
  },
  computed: {
    parsedDescription() {
      return marked(this.newJob.description).replace(/\n/g, '<br>');
    }
  },
  mounted() {
    document.body.style.zoom = "75%";
  },
  methods: {
    showTooltip(field) {
      this.tooltips[field] = true;
    },
    hideTooltip(field) {
      this.tooltips[field] = false;
    },
    async addJob() {
      console.log("Attempting to add job...");

      // Input Validation
      if (!this.newJob.title.trim()) {
        alert("Please enter a job title.");
        return;
      }
      if (!this.newJob.company.trim()) {
        alert("Please enter a company name.");
        return;
      }

      if (!this.newJob.location.trim() && !this.newJob.globallyRemote) {
        alert("Please enter a location or mark the job as globally remote.");
        return;
      }

      // Location validation against cities list
      if (this.newJob.location && !this.newJob.globallyRemote) {
        const validLocation = cities.some(city => 
          `${city.city}, ${city.state}` === this.newJob.location
        );
        if (!validLocation) {
          alert("Invalid location. Please select a valid city from the dropdown.");
          return;
        }
      }

      if (!this.newJob.type) {
        alert("Please select a job type.");
        return;
      }
      if (!this.newJob.description.trim()) {
        alert("Please enter a job description.");
        return;
      }
      if (isNaN(this.newJob.salaryRange.min) || this.newJob.salaryRange.min <= 0) {
        alert("Please enter a valid minimum salary.");
        return;
      }
      if (isNaN(this.newJob.salaryRange.max) || this.newJob.salaryRange.max <= 0) {
        alert("Please enter a valid maximum salary.");
        return;
      }
      if (parseFloat(this.newJob.salaryRange.min) > parseFloat(this.newJob.salaryRange.max)) {
        alert("Minimum salary cannot be greater than maximum salary.");
        return;
      }

      // Validate Skills and Certificates
      const allSkills = this.newJob.requiredSkills.map(skill => skill.skill.toLowerCase()).concat(
        this.newJob.desiredSkills.map(skill => skill.skill.toLowerCase())
      );
      const uniqueSkills = new Set(allSkills);
      if (uniqueSkills.size !== allSkills.length) {
        alert("Each skill should be unique across Required and Desired Skills.");
        return;
      }

      const invalidSkillLevel = this.newJob.requiredSkills.some(skill => !skill.skill.trim() || !skill.desiredLevel) ||
        this.newJob.desiredSkills.some(skill => !skill.skill.trim() || !skill.desiredLevel);
      if (invalidSkillLevel) {
        alert("Each skill must have a name and a selected level.");
        return;
      }

      if (this.stateSpecificRemote && this.newJob.remoteStates.length === 0) {
        alert("Please select at least one state for remote work.");
        return;
      }

      // Convert remoteStates array to a map
      const remoteStatesMap = {};
      this.newJob.remoteStates.forEach(state => {
        remoteStatesMap[state] = true;
      });

      // Automatically add the local state to the remoteStatesMap if locally remote
      if (this.stateSpecificRemote && this.newJob.location) {
        const localState = this.newJob.location.split(', ').pop();
        if (!remoteStatesMap[localState]) {
          remoteStatesMap[localState] = true;
        }
      }

      this.newJob.remoteStatesMap = remoteStatesMap;

      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in!");
        return;
      }

      this.newJob.companyId = user.uid;
      this.newJob.datePosted = new Date();
      this.newJob.remote = this.newJob.globallyRemote || this.stateSpecificRemote;

      try {
        const db = getFirestore(app);
        const jobsCol = collection(db, "jobs");
        await addDoc(jobsCol, this.newJob);
        console.log("Job added successfully");
        this.resetForm();
      } catch (error) {
        console.error("Error adding job: ", error);
      }
    },
    addRequiredSkill() {
      this.newJob.requiredSkills.push({ skill: '', desiredLevel: '' });
    },
    addDesiredSkill() {
      this.newJob.desiredSkills.push({ skill: '', desiredLevel: '' });
    },
    removeSkill(index, isRequired) {
      if (isRequired) {
        this.newJob.requiredSkills.splice(index, 1);
      } else {
        this.newJob.desiredSkills.splice(index, 1);
      }
    },
    addRequiredCertificate() {
      this.newJob.requiredCertificates.push({ certificate: '' });
    },
    removeCertificate(index) {
      this.newJob.requiredCertificates.splice(index, 1);
    },
    onSearch() {
      if (!this.search || this.newJob.globallyRemote) {
        this.isOpen = false;
        return;
      }
      this.isOpen = true;
      this.filteredCities = cities.filter(city => 
        city.city.toLowerCase().startsWith(this.search.toLowerCase()) ||
        city.state.toLowerCase().startsWith(this.search.toLowerCase())
      ).slice(0, 10);
    },
    setLocation(city) {
      this.newJob.location = `${city.city}, ${city.state}`;
      this.newJob.coordinates = city.coordinates;
      this.search = `${city.city}, ${city.state}`;
      this.isOpen = false;
    },
    handleRemoteChange() {
      this.newJob.remote = this.newJob.globallyRemote || this.stateSpecificRemote;
      if (this.newJob.globallyRemote) {
        this.newJob.location = '';
        this.newJob.coordinates = { latitude: null, longitude: null };
        this.newJob.remoteStates = [];
        this.newJob.remoteStatesMap = {};
      }
    },
    resetForm() {
      this.newJob = {
        title: '',
        company: '',
        companyId: '',
        coordinates: { latitude: null, longitude: null },
        datePosted: new Date(),
        description: '',
        desiredSkills: [],
        isActive: true,
        location: '',
        remote: false,
        globallyRemote: false,
        remoteStates: [],
        remoteStatesMap: {},
        requiredSkills: [],
        requiredCertificates: [],
        salaryRange: { min: 0, max: 0 },
        type: ''
      };
      this.stateSpecificRemote = false;
      this.search = '';
    },
    addMarkdown(before, after, type) {
      const textarea = this.$refs.description;
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = textarea.value.substring(start, end);
        const beforeText = textarea.value.substring(0, start);
        const afterText = textarea.value.substring(end);

        // Insert markdown
        this.newJob.description = `${beforeText}${before}${selectedText}${after}${afterText}`;

        // Adjust cursor position
        let cursorPosition;
        if (type === 'newline' || type === 'list') {
          cursorPosition = start + before.length + selectedText.length + after.length;
        } else { // 'bold' or 'italic'
          cursorPosition = start + before.length + (selectedText.length / 2);
        }

        // Refocus and set cursor position
        this.$nextTick(() => {
          textarea.focus();
          textarea.setSelectionRange(cursorPosition, cursorPosition);
        });
      } else {
        console.error('Textarea element not found');
      }
    },
    async generateJobDescription() {
      if (!this.newJob.title.trim() || !this.newJob.company.trim()) {
        alert("Please fill out both the Job Title and Company Name fields to generate a job description.");
        return;
      }

      this.isGenerating = true; // Set loading state to true
      try {
        console.log("Sending request to generate job description...");

        const response = await fetch('https://us-central1-job-board-38af4.cloudfunctions.net/generateJobDescription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            companyName: this.newJob.company,
            jobTitle: this.newJob.title
          })
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error: ${response.statusText} - ${errorText}`);
        }

        const data = await response.json();
        if (data.description) {
          this.newJob.description = data.description.replace(/\\n/g, '\n');
          this.$nextTick(() => {
            this.newJob.parsedDescription = marked(this.newJob.description);
          });
        } else {
          alert("Failed to generate job description. Please try again.");
        }
      } catch (error) {
        alert(`Error generating job description: ${error.message}. Please try again.`);
      } finally {
        this.isGenerating = false; // Reset loading state
      }
    }
  }
}
</script>

<style>
.job-portal {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #f7f8fa;
  padding-top: 100px; /* Adjust this value to move the content down */
  background: #f0f4f8;
}

.form-container {
  width: 45%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.preview-container {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iphone-mockup {
  width: 375px;
  height: 667px;
  border: 16px solid black;
  border-radius: 36px;
  overflow: hidden;
  position: relative;
  background: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.iphone-screen {
  padding: 20px;
  overflow-y: auto;
  height: 627px;
}

.job-preview h2, .job-preview p {
  margin-top: 0;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.skill-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.skill-group input,
.skill-group select {
  margin-right: 10px;
  flex: 1;
}

.autocomplete-suggestions {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  background: white;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  position: absolute;
  z-index: 100;
}

.autocomplete-suggestions li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.autocomplete-suggestions li:hover {
  background-color: #f0f0f0;
}

.markdown-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.markdown-buttons button {
  flex: 1;
  margin: 0 5px;
}

.submit-button {
  background-color: #007BFF;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.add-skill-button {
  background-color: #007BFF;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.add-skill-button:hover {
  background-color: #0056b3;
}

.generate-ai-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.generate-ai-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.generate-ai-button:hover {
  background-color: #218838;
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #007BFF; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.state-checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.state-checkbox {
  width: 50%;
  display: flex;
  align-items: center;
}

.state-checkbox input {
  margin-right: 10px;
}

.tooltip-icon {
  cursor: pointer;
  color: #007BFF;
  margin-left: 5px;
}

.tooltip {
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  margin-top: 5px;
  font-size: 12px;
}

.tooltip-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
</style>
