<template>
  <div class="home">
    <div class="content-container" ref="contentContainer">
      <div class="content" ref="content">
        <div class="main">
          <div class="infographic">
            <h2 style="font-size: 36px;">How It Works</h2>
            <div class="flowchart">
              <div class="section">
                <h3><i class="fas fa-building"></i> For Companies</h3>
                <div class="flow-vertical">
                  <div class="flow-item">
                    <i class="fas fa-user-check"></i>
                    <p><a href="/contact">Request to post ads</a></p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-id-badge"></i>
                    <p>Verify identity</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-upload"></i>
                    <p>Post jobs</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-users"></i>
                    <p>Enjoy qualified applicants!</p>
                  </div>
                </div>
              </div>

              <div class="divider"></div> <!-- Divider between sections -->

              <div class="section">
                <h3><i class="fas fa-users"></i> For Job Seekers</h3>
                <div class="flow-vertical">
                  <div class="flow-item">
                    <i class="fas fa-download"></i>
                    <p>Download the Fylter app</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-user-edit"></i>
                    <p>Input your information & upload resume</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-filter"></i>
                    <p>FYLTER for relevant jobs</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-thumbs-up"></i>
                    <p>Find a job looking for YOUR skills!</p>
                  </div>
                </div>
              </div>

              <div class="divider"></div> <!-- Divider between sections -->

              <div class="section">
                <h3><i class="fas fa-gem"></i> Unique Benefits of Fylter</h3>
                <div class="flow-vertical">
                  <div class="flow-item">
                    <i class="fas fa-clock"></i>
                    <p><strong>Save Time:</strong> Automated skill matching and intuitive mobile interface</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-dollar-sign"></i>
                    <p><strong>Lower Costs:</strong> Avoiding sponsored listings and keyword bidding reduces expenses</p>
                  </div>
                  <div class="arrow">↓</div>
                  <div class="flow-item">
                    <i class="fas fa-search"></i>
                    <p><strong>Diverse Opportunities:</strong> Skill-based matching allows employers to discover job seekers beyond their current industry or job title.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-button-container">
              <button @click="navigateToContact">Ready to Post Jobs or Learn More? Contact us!</button>
            </div>
          </div>
        </div>
        <div class="footer">
          <p>Fylter LLC</p>
          <p>708-654-6066</p>
          <p>Chicago, IL</p>
        </div>
      </div>
      <div class="custom-loader" ref="customLoader">
        <svg ref="svg" viewBox="0 0 200 6000" preserveAspectRatio="xMidYMid meet"></svg>
      </div>
    </div>
    <div class="mock-phone-container" ref="mockPhoneContainer">
      <div class="mock-phone">
        <div class="top-section">
          <div class="status-bar">
            <div class="status-bar-left">
              <i class="fas fa-chevron-left"></i>
            </div>
            <div class="status-bar-right">
              <span class="time"></span>
              <i class="fas fa-signal"></i>
              <i class="fas fa-wifi"></i>
              <i class="fas fa-battery-full"></i>
            </div>
          </div>
        </div>
        <div class="phone-content" ref="phoneContent">
          <div class="circle-container">
            <div class="circle">
              <img src="@/assets/Logo_Without_background.png" alt="Fylter Logo" class="circle-logo">
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="showNewText" key="advertisement" :style="{ opacity: scrollStage / maxScrollStage }">
              <div class="job-details">
                <br>
                <br>
                <br>
                <br>
                <h2 :style="{ color: titleColor }">Software Engineer</h2>
                <p><strong>Company:</strong> Dynamic Tech Solutions</p>
                <p><strong>Salary:</strong> $120,000 - $150,000</p>
                <p>We are looking for a skilled software engineer to join our innovative team. You will be working on state-of-the-art projects, collaborating with experts in the field.</p>
                <p><strong>Responsibilities:</strong></p>
                <ul>
                  <li>Develop and maintain web applications</li>
                  <li>Collaborate with cross-functional teams</li>
                  <li>Ensure the performance, quality, and responsiveness of applications</li>
                </ul>
                <p><strong>Required Skills:</strong></p>
                <ul>
                  <li><i class="fas fa-check-circle" style="color: green;"></i> JavaScript</li>
                  <li><i class="fas fa-check-circle" style="color: green;"></i> React</li>
                </ul>
                <p><strong>Desired Skill:</strong></p>
                <ul>
                  <li><i class="fas fa-times-circle" style="color: red;"></i> TypeScript</li>
                </ul>
              </div>
              <br>
              <div class="slider-container">
                <div class="slider-background">
                  <div class="slider-button" :style="{left: sliderPosition + 'px'}" @mousedown="startDrag" @touchstart="startDrag"></div>
                </div>
              </div>
            </div>
            <div v-else key="welcome">
              <br>
              <br>
              <br>
              <br>
              <br>
              <div class="message-bubble grey">
                <p>Hey there! Looking for your dream job? 🌟</p>
              </div>
              <div class="message-bubble blue">
                <p>Yes! But job searching can be such a hassle. 😩</p>
              </div>
              <div class="message-bubble grey">
                <p>We get it! FYLTER for jobs based on your skills, not just job titles. 💼✨</p>
              </div>
              <div class="message-bubble blue">
                <p>That sounds amazing! How does it work? 🤔</p>
              </div>
              <div class="message-bubble grey">
                <p>Just swipe right to apply or left to pass. Find jobs that truly fit your skills, effortlessly! 😉</p>
              </div>
              <div class="message-input-bar">
                <div class="camera-icon">
                  <i class="fas fa-camera"></i>
                </div>
                <input type="text" placeholder="Message" />
                <div class="send-icon">
                  <i class="fas fa-microphone"></i>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'HomePage',
  data() {
    return {
      showNewText: false,
      scrollStage: 0,
      maxScrollStage: 7,
      titleColor: '#0056b3',
      sliderPosition: 0,
      sliderMax: 300,
      dragging: false,
      scrollProgress: 0,
      secondScreenShown: false // New flag to track if the second screen has been shown
    };
  },
  mounted() {
    this.setZoom();
    this.drawSvgPaths();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setZoom() {
      document.body.style.zoom = "75%";
    },
    navigateToAbout() {
      this.$router.push('/about');
    },
    navigateToContact() {
      this.$router.push('/contact').then(() => {
        window.scrollTo(0, 0);
      });
    },
    startDrag(event) {
      this.dragging = true;
      const startX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX;
      const onMouseMove = (moveEvent) => {
        if (!this.dragging) return;
        const clientX = moveEvent.type.includes('mouse') ? moveEvent.clientX : moveEvent.touches[0].clientX;
        const delta = clientX - startX;
        this.sliderPosition = Math.min(Math.max(0, delta), this.sliderMax);
        if (this.sliderPosition === this.sliderMax) {
          this.dragging = false;
          this.navigateToContact();
        }
      };
      const stopDrag = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', stopDrag);
        window.removeEventListener('touchmove', onMouseMove);
        window.removeEventListener('touchend', stopDrag);
        this.dragging = false;
        this.sliderPosition = 0;
      };
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', stopDrag);
      window.addEventListener('touchmove', onMouseMove);
      window.addEventListener('touchend', stopDrag);
    },
    handleScroll() {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      const startX = window.innerWidth / 2;
      const endX = (2.4 * window.innerWidth) / 4 - (this.$refs.mockPhoneContainer.offsetWidth / 2);

      let translateX = startX;
      if (scrollY > windowHeight / 2) {
        const progress = Math.min((scrollY - windowHeight / 2) / (windowHeight / 2), 1);
        translateX = startX + (endX - startX) * progress;
      }

      translateX = Math.min(translateX, endX);

      this.$refs.mockPhoneContainer.style.transform = `translate(${translateX - this.$refs.mockPhoneContainer.offsetWidth / 2}px, -50%)`;

      const newStage = Math.min(Math.max(0, scrollY / 100), this.maxScrollStage);
      this.scrollStage = newStage;

      if (this.scrollStage === this.maxScrollStage && !this.showNewText) {
        setTimeout(() => {
          this.showNewText = true;
          this.secondScreenShown = true; // Set flag to true when the second screen is shown
        }, 400);
      } else if (this.scrollStage !== this.maxScrollStage && this.showNewText && !this.secondScreenShown) {
        this.showNewText = false;
      }

      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      this.scrollProgress = (scrollY / (docHeight * 300)) * 100;

      this.updatePathVisibility();
    },
    drawSvgPaths() {
      const svg = d3.select(this.$refs.svg);
      const paths = [
        { from: { x: 100, y: 0 }, to: { x: 100, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 50, y: 0 }, to: { x: 150, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 150, y: 0 }, to: { x: 50, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 25, y: 0 }, to: { x: 175, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 175, y: 0 }, to: { x: 25, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 75, y: 0 }, to: { x: 125, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
        { from: { x: 125, y: 0 }, to: { x: 75, y: 10500 }, control1: { x: 100, y: 3000 }, control2: { x: 100, y: 3000 } },
      ];

      paths.forEach((path) => {
        const pathElement = svg.append('path')
          .attr('d', this.getMirroredPath(path.from, path.to, path.control1, path.control2))
          .attr('class', 'line')
          .style('stroke', '#0056b3')
          .style('stroke-width', '10')
          .style('fill', 'none')
          .style('stroke-opacity', '0.50');

        const pathLength = pathElement.node().getTotalLength();
        pathElement
          .attr('stroke-dasharray', `${pathLength} ${pathLength}`)
          .attr('stroke-dashoffset', pathLength);
      });
    },
    getMirroredPath(from, to, control1, control2) {
      return `M ${from.x},${from.y}
              C ${control1.x},${control1.y} ${control2.x},${control2.y} ${to.x},${to.y}
              S ${control2.x},${control2.y} ${from.x},${from.y}`;
    },
    updatePathVisibility() {
      const paths = d3.selectAll('.line').nodes();
      paths.forEach((path) => {
        const pathElement = d3.select(path);
        const pathLength = path.getTotalLength();
        const progress = Math.min(this.scrollProgress, 1);
        pathElement
          .style('stroke-opacity', progress)
          .attr('stroke-dashoffset', pathLength * (1 - progress));
      });
    }
  }
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.home {
  background-color: #f0f4f8; /* Add this line */
}

.top-section {
  background-color: lightgrey;
  height: 14%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.status-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.status-bar-left,
.status-bar-right {
  display: flex;
  align-items: center;
}

.status-bar-left i,
.status-bar-right i {
  font-size: 18px;
  margin: 0 5px;
}

.status-bar-right .time {
  margin-right: 10px;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:5;
}

.circle {
  width: 70px;
  height: 70px;
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-logo {
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #0044cc;
  padding: 10px 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #f0f0f0;
  text-decoration: none;
}

.main {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 150vh;
  padding: 50px;
  margin-top: 1000px;
  transition: transform 0.3s ease;
}

.content {
  width: 50%;
  background: white;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.infographic {
  background: #f0f4f8;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.flowchart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.section {
  margin-bottom: 40px;
}

.section h3 {
  color: #004d99;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.section h3 i {
  margin-right: 10px;
}

.flow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flow-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  position: relative;
}

.flow-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flow-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 2px solid #e3e7eb;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  width: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.flow-item i {
  font-size: 24px;
  margin-bottom: 10px;
  color: #0056b3;
}

.flow-item a {
  color: #0056b3;
  text-decoration: none;
}

.flow-item a:hover {
  text-decoration: underline;
}

.arrow {
  font-size: 24px;
  color: #0056b3;
  margin: 10px;
}

.arrow-right {
  font-size: 24px;
  color: #0056b3;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.divider {
  width: 1px;
  height: 100%;
  background: #e3e7eb;
  margin: 0 20px;
}

.contact-button-container {
  text-align: center;
  margin-top: 20px;
}

.contact-button-container button {
  padding: 10px 20px;
  background-color: #004d99;
  color: #f0f4f8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.contact-button-container button:hover {
  background-color: #003366;
}

.mock-phone-container {
  width: 50%; /* Adjusted width */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 2s ease;
}

.mock-phone {
  width: 350px; /* Adjusted width */
  height: 700px; /* Adjusted height */
  border: 16px solid black;
  border-radius: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  padding: 20px;
  position: relative; /* Added position relative */
}

.phone-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 83%; /* Takes the remaining height after top-section */
  padding-top: 10px;
}

.phone-content h2 {
  font-size: 32px;
  color: #0056b3;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  margin-top: 5px;
  animation: fadeIn 2s ease-out;
  text-align: left;
  width: 90%;
}

.phone-content p, .phone-content ul {
  color: #333;
  font-size: 16px;
  text-align: left;
  margin: 5px 20px;
  width: 90%;
}

.phone-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.header {
  color: #004d99;
  background-color: #f0f4f8;
  padding: 40px 0;
  border-bottom: 2px solid #e3e7eb;
}

.main {
  color: #004d99;
  padding: 20px;
  background-color: #f0f8ff;
  border-bottom: 2px solid #f0f0f0;
}

.footer {
  background-color: #f0f0f0;
  color: #004d99;
  padding: 20px 0;
  text-align: center;
}

button {
  padding: 10px 20px;
  background-color: #004d99;
  color: #f0f4f8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #003366;
}

.slider-container {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 50px;
  user-select: none;
  margin-top: 20px;
}

.slider-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #4CAF50, #8BC34A);
  border-radius: 25px;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-background::after {
  content: "Slide To Start Posting Jobs!";
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.slider-button {
  position: absolute;
  top: 5px;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: left 0.07s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.slider-button::before {
  content: '→';
  color: #666;
}

.slider-button:hover {
  border-color: #bbb;
}

.slider-button:active {
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s, color 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.custom-loader {
  position: fixed;
  right: 5%;
  top: 0;
  width: 50px;
  height: 100%;
}

svg {
  width: 100%;
  height: 100%;
}

.line {
  fill: none;
  stroke: #0056b3;
  stroke-width: 10;
  stroke-opacity: 0.50;
  transition: stroke-opacity 0.2s, stroke-dashoffset 0.2s;
}

.message-bubble {
  position: relative;
  border-radius: 15px;
  padding: 10px 15px;
  max-width: 80%;
  margin: 10px auto;
  text-align: center;
}

.message-bubble.grey {
  background: #e0e0e0;
  color: #000;
  margin-right: 10%;
  align-self: flex-end;
}

.message-bubble.grey::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: -8px;
  border-width: 10px;
  border-style: solid;
  border-color: #e0e0e0 transparent transparent transparent;
  transform: rotate(315deg);
}

.message-bubble.blue {
  background: rgb(0, 120, 254);
  color: #fff;
  margin-left: 10%;
  align-self: flex-start;
}

.message-bubble.blue p {
  color: #fff;
}

.message-bubble.blue::after {
  content: '';
  position: absolute;
  bottom: -5px;
  right: -8px;
  border-width: 10px;
  border-style: solid;
  border-color: rgb(0, 120, 254) transparent transparent transparent;
  transform: rotate(45deg);
}

.message-input-bar {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 25px;
  margin: 10px;
  width: 90%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.message-input-bar .camera-icon,
.message-input-bar .send-icon {
  padding: 5px;
  color: #666;
}

.message-input-bar input {
  flex-grow: 1;
  border: none;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 25px;
  margin: 0 5px;
}

.message-input-bar input:focus {
  outline: none;
}

</style>
