<template>
    <div class="privacy-policy">
      <div class="header">
        <img src="@/assets/Logo_Without_background.png" alt="Fylter Logo" class="logo">
        <h1>Privacy Policy</h1>
      </div>
      <p class="effective-date">Effective Date: 08/08/2024</p>
      <div class="content">
        <h2>1. Introduction</h2>
        <p>Welcome to Fylter! We are committed to protecting your privacy and ensuring you have a positive experience on our website and while using our products and services (collectively, "Services"). This Privacy Policy explains our practices regarding the collection, use, and disclosure of your personal information when you use our Services.</p>
        
        <h2>2. What Is Personal Data at Fylter?</h2>
        <p>At Fylter, we treat any data that relates to an identified or identifiable individual as “personal data.” This includes data that directly identifies you, such as your name, and data that does not directly identify you but could be used to identify you, such as your device's IP address. Aggregated data is considered non-personal data for the purposes of this Privacy Policy.</p>
        
        <h2>3. Your Privacy Rights at Fylter</h2>
        <p>We respect your ability to know, access, correct, transfer, restrict the processing of, and delete your personal data. These rights are available to all our users. You have the right to withdraw your consent at any time if you previously agreed to the processing of your personal data.</p>
        <p>To exercise your privacy rights, please contact us at privacy@fylter.net. We will verify your identity to protect your personal data. If you cannot access our Services to exercise your rights, you can make a request via email or by calling our support number.</p>
        
        <h2>4. Personal Data Fylter Collects from You</h2>
        <p>We strive to collect only the personal data we need to provide and improve our Services. This data includes:</p>
        <ul>
          <li><strong>Account Information</strong>: Your Fylter account details, including your name, email address, and account status.</li>
          <li><strong>Device Information</strong>: Data that identifies your device, such as IP address and browser type.</li>
          <li><strong>Contact Information</strong>: Data such as your name, email address, and phone number.</li>
          <li><strong>Usage Data</strong>: Information about your activity on and use of our Services.</li>
          <li><strong>Location Information</strong>: General location data to support region-specific services.</li>
          <li><strong>Other Information You Provide</strong>: Details such as the content of your communications with Fylter.</li>
        </ul>
        
        <h2>5. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide, operate, and maintain our Services.</li>
          <li>Process your transactions related to job applications and service subscriptions.</li>
          <li>Communicate with you to respond to your inquiries, send important notices, and inform you about our Services.</li>
          <li>Ensure security and prevent fraud to protect our users and Fylter.</li>
          <li>Comply with legal obligations and respond to lawful requests.</li>
        </ul>
        <p>We only share your personal data with potential employers when you apply to a specific job through our platform. We do not share your personal data with third parties for their own marketing purposes.</p>
        
        <h2>6. Sharing of Personal Data</h2>
        <p>We may share personal data with:</p>
        <ul>
          <li><strong>Service Providers</strong>: Third parties that process data on our behalf to help provide and improve our Services.</li>
          <li><strong>Partners</strong>: Third parties we collaborate with to offer services or features.</li>
          <li><strong>Others at Your Direction</strong>: With your consent, such as sharing information with your carrier to activate your account.</li>
        </ul>
        
        <h2>7. Protection of Personal Data</h2>
        <p>We use administrative, technical, and physical safeguards to protect your personal data. These measures are constantly being improved to ensure the highest level of security.</p>
        
        <h2>8. Children and Personal Data</h2>
        <p>Fylter does not knowingly collect personal data from children under the age of 13. If we learn that we have collected such data without verifiable parental consent, we will delete it as soon as possible.</p>
        
        <h2>9. Cookies and Other Technologies</h2>
        <p>Our website and services use cookies and similar technologies to enhance user experience, understand user behavior, and provide personalized services. You can control the use of cookies through your browser settings.</p>
        
        <h2>10. Transfer of Personal Data Between Countries</h2>
        <p>Your personal data may be transferred to and processed in countries outside of your residence. We comply with applicable laws to ensure your data is protected wherever it is processed.</p>
        
        <h2>11. Privacy Questions</h2>
        <p>If you have any questions about Fylter’s Privacy Policy or practices, please contact us at privacy@fylter.net. We take your privacy concerns seriously and will respond promptly.</p>
        
        <h2>12. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. When we make significant changes, we will notify you by posting a notice on our website and contacting you directly if we have your contact information.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #ffffff;
    text-align: center;
  }
  .privacy-policy {
    padding: 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 50px; /* Added padding to prevent logo cut off */
  }
  .logo {
    width: 100px;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 2em;
    margin: 0.5em 0;
  }
  .effective-date {
    font-size: 1em;
    color: #ff4081;
  }
  .content {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  h2 {
    font-size: 1.5em;
    margin: 1em 0;
  }
  p, ul {
    font-size: 1.2em;
    margin: 0.5em 0;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  </style>