<template>
  <div class="company-dashboard">
    <h1>Your Posted Jobs</h1>
    <div class="filters">
      <!-- Existing filters -->
      <div class="filter">
        <label for="job-title-filter">Filter by Job Title:</label>
        <select id="job-title-filter" v-model="selectedJobTitle">
          <option value="">All</option>
          <option v-for="title in jobTitles" :key="title" :value="title">{{ title }}</option>
        </select>
      </div>
      <div class="filter">
        <label for="company-filter">Filter by Company:</label>
        <select id="company-filter" v-model="selectedCompany">
          <option value="">All</option>
          <option v-for="company in companies" :key="company" :value="company">{{ company }}</option>
        </select>
      </div>
      <div class="filter">
        <label for="location-filter">Filter by Location:</label>
        <select id="location-filter" v-model="selectedLocation">
          <option value="">All</option>
          <option v-for="location in locations" :key="location" :value="location">{{ location }}</option>
        </select>
      </div>
      <!-- New isActive filter -->
      <div class="filter">
        <label for="status-filter">Filter by Status:</label>
        <select id="status-filter" v-model="selectedStatus">
          <option value="">All</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>
    <div class="dashboard-content">
      <ul class="jobs-list">
        <li v-for="job in filteredJobs" :key="job.id" @click="selectJob(job)" :class="{'selected-job': selectedJob && job.id === selectedJob.id}">
          <h2>{{ job.title }}</h2>
          <p><strong>Location:</strong> {{ job.location }}</p>
          <p v-if="job.remoteStatesList && job.remoteStatesList.length"><strong>Remote States:</strong> {{ job.remoteStatesList.join(', ') }}</p>
          <p><strong>Applicants:</strong> {{ job.applicantsCount }}</p>
          <p><strong>Salary:</strong> ${{ job.salaryRange.min.toLocaleString() }} - ${{ job.salaryRange.max.toLocaleString() }}</p>
          <p><strong>Date Posted:</strong> {{ formatDate(job.datePosted) }}</p>
          <p><strong>Type:</strong> {{ job.type }}</p>
          <p><strong>Company:</strong> {{ job.company }}</p>
          <p>
            <strong>Status:&nbsp;</strong>
            <span :class="{'active-status': job.isActive, 'inactive-status': !job.isActive, 'status-text': true}">
              {{ job.isActive ? 'Active' : 'Inactive' }}
            </span>
            <button :class="['status-button', job.isActive ? 'deactivate' : 'activate']" @click.stop="toggleActiveStatus(job)">
              {{ job.isActive ? 'Deactivate' : 'Activate' }}
            </button>
          </p>
          <div class="button-group">
            <button :class="['toggle-button', { 'selected-button': selectedJob && job.id === selectedJob.id && job.previewMode === 'applicants' }]" @click.stop="togglePreview(job, 'applicants')">Show Applicants</button>
            <button :class="['toggle-button', { 'selected-button': selectedJob && job.id === selectedJob.id && job.previewMode === 'advertisement' }]" @click.stop="togglePreview(job, 'advertisement')">Preview Advertisement</button>
          </div>
          <!-- Delete Button on its own line -->
          <div class="delete-button-group">
            <button class="delete-button" @click.stop="confirmDeleteJob(job)">Permanently Delete Job</button>
          </div>
        </li>
      </ul>
      <div class="details-panel" v-if="selectedJob">
        <div class="details-header">
          <button class="export-button" v-if="selectedJob.previewMode === 'applicants'" @click="exportApplicantsToCSV()">Export to CSV</button>
        </div>
        <div class="details-content">
          <div v-if="selectedJob.previewMode === 'applicants'" class="applicant-details">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Skills</th>
                  <th>Resume URL</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Education</th>
                  <th>Certifications</th>
                  <th>Phone Number</th>
                  <th>Applied On</th>
                  <th>Application ID</th>
                  <th>User ID</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="applicant in applicants" :key="applicant.id">
                  <td>{{ applicant.firstName }} {{ applicant.lastName }}</td>
                  <td>{{ applicant.email }}</td>
                  <td>{{ applicant.skills.map(skill => skill.skill + ' (' + skill.level + ')').join(', ') }}</td>
                  <td><a :href="applicant.resumeUrl" target="_blank">Resume</a></td>
                  <td>{{ applicant.city }}</td>
                  <td>{{ applicant.state }}</td>
                  <td>{{ applicant.education.map(edu => edu.degree + ' in ' + edu.fieldOfStudy).join(', ') }}</td>
                  <td>{{ applicant.certifications.join(', ') }}</td>
                  <td>{{ applicant.phoneNumber }}</td>
                  <td>{{ applicant.appliedOn }}</td>
                  <td>{{ applicant.applicationId }}</td>
                  <td>{{ applicant.userId }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selectedJob.previewMode === 'advertisement'" class="iphone-mockup">
            <div class="iphone-screen">
              <h2>{{ selectedJob.title }}</h2>
              <p><strong>Company:</strong> {{ selectedJob.company }}</p>
              <p><strong>Location:</strong> {{ selectedJob.location }}</p>
              <p><strong>Salary:</strong> ${{ selectedJob.salaryRange.min.toLocaleString() }} - ${{ selectedJob.salaryRange.max.toLocaleString() }}</p>
              <h3 v-if="selectedJob.requiredSkills.length > 0">Required Skills</h3>
              <ul v-if="selectedJob.requiredSkills.length > 0">
                <li v-for="(skill, index) in selectedJob.requiredSkills" :key="'req-skill-' + index">
                  <span class="green-check">✔️</span> {{ skill.skill }} ({{ skill.desiredLevel }})
                </li>
              </ul>
              <h3 v-if="selectedJob.desiredSkills.length > 0">Desired Skills</h3>
              <ul v-if="selectedJob.desiredSkills.length > 0">
                <li v-for="(skill, index) in selectedJob.desiredSkills" :key="'des-skill-' + index">
                  <span class="green-check">✔️</span> {{ skill.skill }} ({{ skill.desiredLevel }})
                </li>
              </ul>
              <h3 v-if="selectedJob.requiredCertificates.length > 0">Required Certificates</h3>
              <ul v-if="selectedJob.requiredCertificates.length > 0">
                <li v-for="(certificate, index) in selectedJob.requiredCertificates" :key="'req-cert-' + index">
                  <span class="green-check">✔️</span> {{ certificate.certificate }}
                </li>
              </ul>
              <div class="job-description" v-html="selectedJob.parsedDescription"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase';
import { marked } from 'marked';
import { saveAs } from 'file-saver'; // Ensure you have file-saver installed via npm

export default {
  data() {
    return {
      jobs: [],
      selectedJob: null,
      applicants: [],
      previewMode: null, // Handles the toggle between applicant details and job advertisement preview
      selectedJobTitle: "", // Data property for the selected job title filter
      selectedCompany: "", // Data property for the selected company filter
      selectedLocation: "", // Data property for the selected location filter
      selectedStatus: "" // Data property for the selected isActive filter
    };
  },
  computed: {
    filteredJobs() {
      return this.jobs.filter(job => {
        const matchesTitle = !this.selectedJobTitle || job.title === this.selectedJobTitle;
        const matchesCompany = !this.selectedCompany || job.company === this.selectedCompany;
        const matchesLocation = !this.selectedLocation || job.location === this.selectedLocation;
        const matchesStatus = this.selectedStatus === "" || job.isActive.toString() === this.selectedStatus;
        return matchesTitle && matchesCompany && matchesLocation && matchesStatus && !job.isDeleted;
      });
    },
    jobTitles() {
      return [...new Set(this.jobs.map(job => job.title))];
    },
    companies() {
      return [...new Set(this.jobs.map(job => job.company))];
    },
    locations() {
      return [...new Set(this.jobs.map(job => job.location))];
    }
  },
  methods: {
    async fetchJobs(userId) {
      const db = getFirestore();
      const jobsCol = collection(db, "jobs");
      const q = query(jobsCol, where("companyId", "==", userId));
      const querySnapshot = await getDocs(q);
      const jobsWithDetails = await Promise.all(querySnapshot.docs.map(async doc => {
        const job = doc.data();
        job.id = doc.id;
        job.applicantsCount = await this.fetchApplicantsCount(doc.id);
        job.salaryRange = job.salaryRange || { min: 0, max: 0 };
        job.requiredSkills = job.requiredSkills || [];
        job.desiredSkills = job.desiredSkills || [];
        job.requiredCertificates = job.requiredCertificates || []; // Initialize requiredCertificates if it doesn't exist
        job.isActive = job.isActive || false;
        job.isDeleted = job.isDeleted || false; // Initialize isDeleted if it doesn't exist
        job.parsedDescription = marked(job.description ? job.description.replace(/\\n/g, '\n') : '');
        job.previewMode = null; // Initialize previewMode for each job
        job.remoteStatesList = job.remoteStatesMap ? Object.keys(job.remoteStatesMap) : [];
        return job;
      }));
      this.jobs = jobsWithDetails;
    },
    async fetchApplicantsCount(jobId) {
      const db = getFirestore();
      const applicantsCol = collection(db, `jobs/${jobId}/applicants`);
      const applicantsSnapshot = await getDocs(applicantsCol);
      return applicantsSnapshot.size;
    },
    selectJob(job) {
      this.selectedJob = job;
      this.fetchApplicantsDetails(job.id);
    },
    async fetchApplicantsDetails(jobId) {
      const db = getFirestore();
      const applicantsCol = collection(db, `jobs/${jobId}/applicants`);
      const applicantsSnapshot = await getDocs(applicantsCol);
      this.applicants = applicantsSnapshot.docs.map(docSnap => {
        const applicantData = docSnap.data();
        const [city, state] = applicantData.location ? applicantData.location.split(",") : ["No City Provided", "No State Provided"];
        return {
          id: docSnap.id,
          applicationId: applicantData.applicationId || 'No Application ID Provided',
          appliedOn: applicantData.appliedOn ? new Date(applicantData.appliedOn.seconds * 1000).toLocaleDateString() : 'No Applied Date Provided',
          certifications: applicantData.certifications || [],
          education: applicantData.education || [],
          email: applicantData.email || 'No Email Provided',
          firstName: applicantData.firstName || 'No First Name Provided',
          lastName: applicantData.lastName || 'No Last Name Provided',
          city: city.trim(),
          state: state.trim(),
          phoneNumber: applicantData.phoneNumber || 'No Phone Number Provided',
          resumeUrl: applicantData.resumeUrl || 'No Resume Provided',
          skills: applicantData.skills || [],
          userId: applicantData.userId || 'No User ID Provided'
        };
      });
    },
    formatDate(value) {
      if (value) {
        const date = new Date(value.seconds * 1000);
        return date.toLocaleDateString();
      }
      return 'N/A';
    },
    toggleActiveStatus(job) {
      const db = getFirestore();
      const jobRef = doc(db, "jobs", job.id);
      const newStatus = !job.isActive;
      updateDoc(jobRef, {
        isActive: newStatus
      }).then(() => {
        job.isActive = newStatus; // Update the local state to reflect the change immediately
      });
    },
    async confirmDeleteJob(job) {
      const confirmed = confirm('Applicant data will be lost for the deleted job. Please confirm you would like to delete.');
      if (confirmed) {
        const db = getFirestore();
        const jobRef = doc(db, "jobs", job.id);
        await updateDoc(jobRef, {
          isDeleted: true,
          isActive: false // Set isActive to false when isDeleted is confirmed
        });
        this.jobs = this.jobs.filter(j => j.id !== job.id); // Remove the job from the local state
      }
    },
    exportApplicantsToCSV() {
      const headers = ["Name", "Email", "Skills", "Resume URL", "City", "State", "Education", "Certifications", "Phone Number", "Applied On", "Application ID", "User ID"];
      const rows = this.applicants.map(applicant => [
        `${applicant.firstName} ${applicant.lastName}`,
        applicant.email,
        applicant.skills.map(skill => `${skill.skill} (${skill.level})`).join(', '),
        applicant.resumeUrl,
        applicant.city,
        applicant.state,
        applicant.education.map(edu => `${edu.degree} in ${edu.fieldOfStudy}`).join(', '),
        applicant.certifications.join(', '),
        applicant.phoneNumber,
        applicant.appliedOn,
        applicant.applicationId,
        applicant.userId
      ]);
      const csvContent = [
        headers.join(","),
        ...rows.map(e => e.join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, "applicants.csv");
    },
    togglePreview(job, mode) {
      if (job.previewMode === mode) {
        job.previewMode = null; // Toggle off if the same mode is clicked again
      } else {
        job.previewMode = mode; // Set to the desired mode
      }
      // Update the selectedJob's previewMode if it's the same job
      if (this.selectedJob && this.selectedJob.id === job.id) {
        this.selectedJob.previewMode = job.previewMode;
      }
    }
  },
  created() {
    onAuthStateChanged(auth, user => {
      if (user) {
        this.fetchJobs(user.uid);
      } else {
        this.$router.push('/login');
      }
    });
  },
  mounted() {
    document.body.style.zoom = "75%";
  }
}
</script>

<style scoped>
.company-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f4f8;
  padding-top: 100px;
}

.filters {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter label {
  margin-bottom: 5px;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.jobs-list {
  width: 45%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.jobs-list li {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.jobs-list li:hover,
.jobs-list li.selected-job {
  background-color: #007BFF;
  color: white;
}

.jobs-list li.selected-job h2,
.jobs-list li.selected-job p {
  color: white;
}

.status-text {
  margin-right: 10px;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.active-status {
  background-color: #28a745;
}

.inactive-status {
  background-color: #dc3545;
}

.status-button {
  background-color: transparent;
  border: none;
  color: #007BFF;
  cursor: pointer;
  padding: 5px 15px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.status-button:hover {
  text-decoration: underline;
}

.status-button.activate {
  background-color: #28a745;
  color: white;
}

.status-button.deactivate {
  background-color: #dc3545;
  color: white;
}

.status-button.activate:hover,
.status-button.deactivate:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-button {
  margin-top: 10px;
  background-color: #0056b3;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, outline 0.3s, box-shadow 0.3s;
}

.toggle-button:hover {
  background-color: #004494;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-button.selected-button {
  outline: 2px solid #ffcc00;
  background-color: #003d80;
}

.button-group {
  display: flex;
  gap: 10px; /* Add space between buttons */
}

.details-panel {
  width: 50%;
  background-color: #f0f4f8;
  padding: 40px;
  border-radius: 20px;
  overflow-y: auto;
  max-height: 100vh;
  position: sticky;
  top: 20px;
}

.details-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.details-content {
  overflow-y: visible;
  max-height: none;
}

.applicant-details table {
  width: 100%;
  border-collapse: collapse;
}

.applicant-details th,
.applicant-details td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}

.applicant-details th {
  background-color: #f2f2f2;
}

.export-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.export-button:hover {
  background-color: #218838;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.iphone-mockup {
  width: 375px;
  height: 667px;
  border: 16px solid black;
  border-radius: 36px;
  overflow: hidden;
  position: relative;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.iphone-screen {
  padding: 20px;
  height: calc(100% - 40px); /* Adjust the height to ensure it fits within the mockup */
  overflow-y: scroll;
  box-sizing: border-box;
}

.job-description {
  margin: 0; /* Reset margins */
  padding: 0; /* Reset padding */
}

.job-description h3 {
  font-weight: bold;
  color: #333;
  font-size: 1.2em;
  margin: 10px 0;
}

.delete-button-group {
  margin-top: 10px; /* Add space above the delete button */
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.delete-button:hover {
  background-color: #c82333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.green-check {
  color: green;
  margin-right: 5px;
}
</style>
