<template>
  <div class="contact-page">
    <!-- Hero Section -->
    <div class="hero-section">
    </div>
    <div class="contact-container">
      <h2>Send Us a Message</h2> <!-- Added form title -->
      <p class="intro-text">
        Whether you're looking to join our platform, have suggestions, or just want to talk, we're here to listen. Choose your reason for contacting us below and leave your information. We're excited to connect with you!
      </p>
      <form @submit.prevent="sendContactRequest" class="contact-form">
        <select v-model="contactReason" required :disabled="isSubmitting">
          <option disabled value="">Please select your reason for contacting us</option>
          <option>Register my company to post jobs</option>
          <option>Make recommendations</option>
          <option>General inquiry</option>
        </select>
        <input type="text" v-model="companyName" placeholder="Company Name" required :disabled="isSubmitting">
        <input type="email" v-model="email" placeholder="Contact Email" required :disabled="isSubmitting">
        <textarea v-model="message" placeholder="Your Message" required :disabled="isSubmitting"></textarea>
        <div ref="recaptcha" class="g-recaptcha" :data-sitekey="recaptchaSiteKey"></div>
        <br>
        <button type="submit" class="submit-btn" :disabled="isSubmitting">Send Request</button>
      </form>
    </div>
    <!-- FAQ Section with Accordion Style -->
    <div class="faq-section">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li v-for="(faq, index) in faqs" :key="index">
          <button @click="toggleFAQ(index)" class="faq-question">
            {{ faq.question }}
          </button>
          <div v-if="faq.open" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- Map and Address Section -->
    <div class="map-address-section">
      <h2>Visit Us in Chicago</h2>
      <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.9925114475523!2d-87.62442158425066!3d41.88265647922169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2ca4bc35b0f5%3A0x69a168e1a8fc8d52!2sMillennium%20Park!5e0!3m2!1sen!2sus!4v1640883968432" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
      <p class="address">Chicago, IL</p>
    </div>
  </div>
</template>

<script>
/* global grecaptcha */

import { app } from '../firebase'; // Adjust the path as necessary
import { getFunctions, httpsCallable } from 'firebase/functions';

export default {
  data() {
    return {
      contactReason: '',
      companyName: '',
      email: '',
      message: '',
      isSubmitting: false, // Track the submission state
      recaptchaSiteKey: '6LdKiucpAAAAALzYCFAfeFgKPNRwPcOHox-Vr6x_', // Replace with your actual reCAPTCHA site key
      faqs: [
        { question: "How does Fylter match job seekers with job listings?", answer: "We use AI and other matching strategies to match job listings with job seekers based on a detailed analysis of skills and requirements, ensuring that matches are relevant and beneficial for both parties.", open: false },
        { question: "What makes Fylter different from other job boards?", answer: "Unlike traditional job boards that rely on job aggregation and paid listings, we focus on skill-based matching and ensure fair visibility for all jobs without extra costs.", open: false },
        { question: "Will posting a job on your platform cost me extra for better visibility?", answer: "No, we do not charge extra for better visibility. All jobs are matched to candidates based on the relevance of their skills, not on payment to us.", open: false },
        { question: "Can job seekers find jobs that match their skills even if they are not actively searching for those specific job titles?", answer: "Yes, our platform recommends jobs based on skills compatibility rather than specific job titles, which can open up more suitable opportunities for job seekers.", open: false },
        { question: "What should I do if I want to register my company on your platform?", answer: "Please use our contact form to send your company details. We prioritize ensuring a legitimate and secure setup for each company before listing jobs.", open: false },
        // Additional questions can be added here as needed
      ]
    };
  },
  mounted() {
    document.body.style.zoom = "75%";
    this.loadRecaptcha();
  },
  methods: {
    loadRecaptcha() {
      const script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = this.renderRecaptcha;
      document.head.appendChild(script);
    },
    renderRecaptcha() {
      if (window.grecaptcha) {
        window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.recaptchaSiteKey,
          callback: this.onRecaptchaVerified
        });
      } else {
        setTimeout(this.renderRecaptcha, 500);
      }
    },
    async sendContactRequest() {
      this.isSubmitting = true; // Disable the form
      const recaptchaToken = grecaptcha.getResponse(); // Get reCAPTCHA token
      if (!recaptchaToken) {
        alert('Please complete the reCAPTCHA');
        this.isSubmitting = false;
        return;
      }
      const functions = getFunctions(app);
      const sendEmail = httpsCallable(functions, 'sendEmail');
      const fullMessage = `Contact Reason: ${this.contactReason}\n\nCompany Name: ${this.companyName}\nEmail: ${this.email}\nMessage: ${this.message}\nreCAPTCHA Token: ${recaptchaToken}`; // Format the full message
      try {
        const result = await sendEmail({
          message: fullMessage  // Send the formatted message
        });
        alert('Thank you for reaching out! We will contact you soon.');
        console.log(result.data);
      } catch (error) {
        console.error('Failed to send email:', error);
        alert('Failed to send your request. Please try again.');
      }
      this.isSubmitting = false; // Re-enable the form
      this.contactReason = '';
      this.companyName = '';
      this.email = '';
      this.message = '';
      grecaptcha.reset(); // Reset reCAPTCHA
    },
    toggleFAQ(index) {
      this.faqs[index].open = !this.faqs[index].open; // Toggle the 'open' property of the FAQ item
    }
  }
}
</script>

<style scoped>
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background: #f0f4f8;
}

.hero-section {
  width: 100%;
  height: 300px; /* Reduced height for better balance */
  background: url('../assets/hero_image_of_split-screen_image_with_job_seekers.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.contact-container, .faq-section, .map-address-section {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  width: 100%;  /* Ensuring all form elements fill the form equally */
  margin-bottom: 10px;
  padding: 12px; /* Increased padding */
  border: 1px solid #ccc;
  box-sizing: border-box; /* This includes padding and border in the width */
}

input:disabled, textarea:disabled, select:disabled, button:disabled {
  opacity: 0.5; /* Makes the form elements look inactive */
  cursor: not-allowed;
}

input, textarea, select {
  width: 100%;
  margin-bottom: 10px;
  padding: 12px; /* Increased padding */
  border: 1px solid #ccc;
}

.intro-text, p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.submit-btn {
  background-color: #0056b3;
  color: white;
  padding: 12px 20px; /* Increased padding for better clickability */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover, .submit-btn:disabled {
  background-color: #004494; /* Consistent color change on hover and disable */
}

.faq-question {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #0056b3;
  cursor: pointer;
}

.faq-answer {
  padding: 0 20px;
  text-align: left;
}

.map {
  text-align: center;
  margin-bottom: 20px;
}

.address {
  text-align: center;
  font-size: 16px;
  color: #333;
}
</style>
