<template>
  <div class="navbar">
    <div class="brand">
      <img src="@/assets/Logo_Without_background.png" alt="Fylter Logo" class="logo">
      Fylter
    </div>
    <div class="separator"></div>
    <nav>
      <ul>
        <li><a @click.prevent="navigateTo('/')">Home</a></li>
        <li><a @click.prevent="navigateTo('/about')">About</a></li>
        <li><a @click.prevent="navigateTo('/contact')">Contact</a></li>
        <li><a @click.prevent="navigateTo('/auth')">Sign In / Sign Up</a></li>
        <li v-if="isCompanyUser"><a @click.prevent="navigateTo('/jobs')">Job Portal</a></li>
        <li v-if="isCompanyUser"><a @click.prevent="navigateTo('/company-dashboard')">Dashboard</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'AppNavBar',
  data() {
    return {
      isCompanyUser: false
    };
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          this.isCompanyUser = !!idTokenResult.claims.companyUser;
        });
      }
    });
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  background-color: #f0f4f8;
  padding: 15px 20px;
  color: #f0f4f8;;
  position: fixed;
  top: 0;
  width: 97%;
  z-index: 1000;
  margin: 0 auto;
}

.brand {
  display: flex;
  align-items: center;
  font-size: 26px;
  color: #004d99;
  font-weight: bold;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.separator {
  flex-grow: 1;
  height: 2px;
  background-color: #004d99;
  margin: 0 20px;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

li {
  margin: 0 15px;
}

a {
  color: #004d99;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

a:hover {
  color: #4CAF50;
  text-decoration: underline;
}

.router-link-active, .active-link {
  background-color: #004d99;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Add some padding to the body to avoid content being hidden behind the navbar */
body {
  padding-top: 70px;
}
</style>
