import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import AboutPage from '@/components/AboutPage.vue';
import ContactPage from '@/components/ContactPage.vue';
import AuthPage from '@/components/AuthPage.vue';
import JobPortal from '@/components/JobPortalPage.vue';
import CompanyDashboard from '@/components/CompanyDashboard.vue';
import OptimizePostings from '@/components/OptimizePostings.vue';
import LandingPage from '@/components/LandingPage.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/about', component: AboutPage },
  { path: '/contact', component: ContactPage },
  { path: '/auth', component: AuthPage },
  { path: '/jobs', component: JobPortal },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/landing', component: LandingPage, meta: { hideNavbar: true } },
  { path: '/optimize-postings', component: OptimizePostings },
  {
    path: '/company-dashboard',
    name: 'CompanyDashboard',
    component: CompanyDashboard,
    meta: {
      requiresAuth: true,
      isCompanyUser: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
