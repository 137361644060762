<template>
  <div class="landing">
    <div class="header">
      <img src="@/assets/Logo_Without_background.png" alt="Fylter Logo" class="logo">
      <h1>Fylter</h1>
    </div>
    <p class="slogan">Your Gateway to Career Success</p>
    <p>Swipe a job today. #1 fast hire jobs app.</p>
    
    <div class="links">
      <button class="link-button">Download the app 💗</button>
      <!-- Commented out placeholders for now -->
      <!-- <img src="@/assets/qr-ios.png" alt="Download on iOS">
      <img src="@/assets/qr-android.png" alt="Download on Android"> -->
      <button class="link-button">RSVP Jobs Match Event</button>
      <button class="link-button">About Us</button>
      <button class="link-button">Reach out</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage'
}
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: #ffffff;
  text-align: center;
}
.landing {
  padding: 20px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.logo {
  width: 100px;
  margin-bottom: 10px;
}
h1 {
  font-size: 2em;
  margin: 0.5em 0;
}
.slogan {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5em 0;
  color: #ff4081;
}
p {
  font-size: 1.2em;
  margin: 0.5em 0;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.link-button {
  background-color: #1e1e1e;
  color: #ffffff;
  border: 2px solid #ff4081;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  margin: 10px 0;
  width: 80%;
}
.link-button:hover {
  background-color: #ff4081;
  color: #1e1e1e;
}
@media (max-width: 600px) {
  h1 {
    font-size: 1.5em;
  }
  .slogan {
    font-size: 1.2em;
  }
  p {
    font-size: 1em;
  }
  .link-button {
    font-size: 1em;
    padding: 8px 16px;
  }
}
</style>
