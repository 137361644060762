<template>
  <div class="auth-container">
    <div class="form-container">
      <h1 v-if="user">Hi {{ userEmail }}, welcome back!</h1>
      <h1 v-else>Sign In</h1>
      <form @submit.prevent="signIn" v-if="!user">
        <input type="email" v-model="email" placeholder="Email" required>
        <input type="password" v-model="password" placeholder="Password" required>
        <button type="submit">Sign In</button>
        <p>Need to Post Jobs? <router-link to="/contact">Contact us!</router-link></p>
      </form>
      <div v-else>
        <p><router-link to="/jobs">Go to Job Portal</router-link></p>
        <button @click="signOutUser">Sign Out</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { auth } from '@/firebase';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const user = ref(null);
    const userEmail = ref('');

    onMounted(() => {
      document.body.style.zoom = "75%";
      
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          user.value = authUser;
          userEmail.value = authUser.email;
        } else {
          user.value = null;
        }
      });
    });

    const signIn = () => {
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
          console.log('Signed in:', userCredential.user);
          email.value = '';
          password.value = '';
        })
        .catch((error) => {
          console.error('Signin error:', error.message);
          alert('Signin failed: ' + error.message);
        });
    };

    const signOutUser = () => {
      signOut(auth)
        .then(() => {
          console.log('User signed out');
          user.value = null;
        })
        .catch((error) => {
          console.error('Error signing out:', error);
        });
    };

    return { email, password, user, userEmail, signIn, signOutUser };
  }
}
</script>

<style scoped>
.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f4f8
}

.form-container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

input, button {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
}

button {
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  border: none;
}

router-link {
  color: #007BFF;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}
</style>
