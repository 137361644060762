import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { MotionPlugin } from '@vueuse/motion'
// Importing a global stylesheet if necessary
import './assets/styles.css';

const app = createApp(App);
app.use(MotionPlugin) 
app.use(router);  // Correctly using the router with Vue 3
app.mount('#app');
