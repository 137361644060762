import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDxRsbVOOjiPhvgyiDxnHe-YbBg2jq1b-0",  // Your API key
  authDomain: "job-board-38af4.firebaseapp.com",  // Your Auth Domain
  projectId: "job-board-38af4",
  storageBucket: "job-board-38af4.appspot.com",
  messagingSenderId: "923505374158",  // Your Project number, often used as Messaging Sender ID
  appId: "1:923505374158:android:ddea0b61817d8d2ba0b19f"  // Look this up in your Firebase console under your app settings
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Auth service from the Firebase instance
const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db, app };
