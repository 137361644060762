<template>
    <div class="optimize-postings">
      <h1>How to Optimize Job Listings</h1>
      <section>
        <h2>Job Title</h2>
        <p>Specificity is key. Avoid long titles as they do not display well on mobile apps and are not appealing to job seekers.</p>
      </section>
      <section>
        <h2>Company Name</h2>
        <p>Ensure accuracy, but note that the company name does not impact the matching process.</p>
      </section>
      <section>
        <h2>Location</h2>
        <p>Enter the city and state if the job is not globally remote. Use the autocomplete suggestions to ensure a valid location.</p>
      </section>
      <section>
        <h2>Job Type</h2>
        <p>Select the appropriate job type. Job seekers will only see jobs that match the type they are searching for.</p>
      </section>
      <section>
        <h2>Remote Options</h2>
        <p>Check "Globally Remote" if the job can be performed from anywhere in the world. For hybrid roles requiring commuting, do not mark the job as remote as it will negatively impact conversions.</p>
      </section>
      <section>
        <h2>Job Description</h2>
        <p>Keep descriptions concise, with a maximum of 150 words. Generally, 100-125 words are preferred by mobile users. Clearly describe the role and responsibilities.</p>
      </section>
      <section>
        <h2>Skills</h2>
        <p>Skills have a significant impact on matching. List 2-3 required skills and 2-3 desired skills. Avoid listing skills as required unless they are absolutely necessary, as this will heavily penalize job seekers who lack them.</p>
      </section>
      <section>
        <h2>Salary Range</h2>
        <p>Provide a realistic salary range to attract suitable candidates. Ensure the minimum salary is not greater than the maximum salary.</p>
      </section>
      <section>
        <h2>Additional Notes</h2>
        <p>Note that candidates can filter by job age, wage, and remote options, so ensure these details are accurate to improve visibility and relevance.</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OptimizePostings',
    mounted() {
      document.body.style.zoom = "75%";
    }
  }
  </script>
  
  <style>
  .optimize-postings {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .optimize-postings h1 {
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
  }
  
  .optimize-postings section {
    margin-bottom: 20px;
  }
  
  .optimize-postings h2 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  
  .optimize-postings p {
    font-size: 16px;
    line-height: 1.5;
  }
  </style>
  